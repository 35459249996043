<template>
  <div
      class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
      style="height: 100vh; overflow: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-setting-left-menu></contract-setting-left-menu>
        <div class="h-100 flex-auto text-align-left">
          <div class="flex flex-column h-100 w-100 pl1 pr4">
            <MetaRightSideHeader>
              <template #title>角色</template>
              <template #middle-right>
                <el-button type="primary" @click="saveRoleInfo">保存设置</el-button>
                <el-button v-if="roleInfoPermissions.roleType == 1  && roleInfoPermissions.id != null" type="danger" @click="deleteRoleInfo">删除</el-button>
              </template>
            </MetaRightSideHeader>
<!--            <header class="pv3">-->
<!--              <div class="mt2 pt1 flex justify-between items-start">-->
<!--                <div class="pr4">-->
<!--                  <h1 class="fw4 f4 gray mv0 pr4 flex items-center size-medium">-->
<!--                    角色-->
<!--                  </h1>-->
<!--                </div>-->
<!--                <div class="pr4">-->
<!--                  <el-button type="primary" @click="saveRoleInfo">保存设置</el-button>-->
<!--                  <el-button v-if="roleInfoPermissions.roleType == 1  && roleInfoPermissions.id != null" type="danger" @click="deleteRoleInfo">删除</el-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </header>-->
            <div class="data-container shadow" style="flex: 1;height: calc(100% - 50px);">
              <el-scrollbar style="height: calc(100% - 50px)">
                <div style="padding-left: 28px;padding-top: 28px;padding-right: 28px">
                  <div class="fw5">
                    <h2 class="gray f5 ttu mb3 mt0">
                      角色基本设置
                    </h2>
                    <div class="mt3 flex items-start">
                      <label class="dib min-w4 mt2 mb2 silver f6">
                        角色名
                      </label>
                      <el-input v-if="roleInfoPermissions.roleType == 0" disabled class="dib w-40" clearable v-model="roleInfoPermissions.name"></el-input>
                      <el-input v-else class="dib w-40" maxlength="15" clearable v-model="roleInfoPermissions.name" :class="warnMessageShow ? 'warningMessage' : null"></el-input>
                    </div>
                    <div v-if="warnMessageShow" class="mt3 flex items-start">
                      <label class="dib min-w4 mt2 mb2 silver f6">
                      </label>
                      <span class = "db dark-red mt1">请输入最少三个字的角色名</span>
                    </div>
                    <div class="mt3 flex" >
                      <label class="dib min-w4 mt2 mb2 silver f6">
                        描述
                      </label>
                      <el-input v-if="roleInfoPermissions.roleType == 0" disabled class="dib w-40" clearable v-model="roleInfoPermissions.description"></el-input>
                      <el-input v-else class="dib w-40" maxlength="40" clearable v-model="roleInfoPermissions.description"></el-input>
                    </div>
                  </div>
                  <el-divider style="width: auto"></el-divider>
                  <div class="fw5">
                    <h2 class="gray f5 ttu mb3 mt0" style="margin-bottom: 5px">
                      添加用户
                    </h2>
                    <div class="tr">
                      <el-button size="small" type="primary" @click="addOrganizationUserClicked">添加用户</el-button>
                    </div>
                    <div class="ember-table-overflow" style="margin-top: 15px">
                      <el-table
                         :data="rolePermissionsUsers"
                         empty-text=" "
                      >
                        <el-table-column
                                width="50"
                        >
                          <template #default="scope">
                            <user-icon :url="scope.row.headImgUrl" :name="scope.row.userName" :uid="scope.row.userName"></user-icon>
                          </template>
                        </el-table-column>
                        <el-table-column
                                label="用户名"
                                prop="userName"
                                min-width="350"
                        >
                        </el-table-column>
                        <el-table-column
                                label="邮箱"
                                prop="email"
                                min-width="350"
                        >
                        </el-table-column>
                        <el-table-column
                                label="联系电话"
                                prop="phone"
                                min-width="100"
                        >
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>

                  <div class="fw5" style="margin-top: 28px">
                    <h2 class="gray f5 ttu mb3 mt0">
                      具有此角色的用户可用的权限
                    </h2>
                    <p class="fw4 mb4 silver">注意：可以通过作为文档创建者、通过文件夹访问、通过标签（对于未启用文件夹的帐户）以及邀请用户访问单个文档来授予文档访问权限。</p>
                    <div class="ember-table-overflow">
                      <el-table
                              :data="permissionsTempList"
                              empty-text=" "
                      >
                        <el-table-column
                                label="权限"
                                min-width="150"
                                prop="name"
                        >
                        </el-table-column>
                        <el-table-column
                                label="描述"
                                min-width="250"
                                prop="description"
                        >

                        </el-table-column>
                        <el-table-column
                                width="100"
                        >
                          <template #default="scope">
                            <el-switch v-if="roleInfoPermissions.roleType == 0" disabled v-model="scope.row.isPermission" @click="refreshRRPermissions(scope.row)"></el-switch>
                            <el-switch v-else v-model="scope.row.isPermission" @click="refreshRRPermissions(scope.row)"></el-switch>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>

                <el-dialog
                        title="添加用户"
                        v-model="addUserDialogVisible"
                        @opened="addUserDialogOpened"
                        v-if="addUserDialogVisible"
                        center>
                  <div style="height:50vh">
                    <organizationUserSelection
                            ref="organization-user-selection"
                            select_organization="false"
                            :tool_title="'成员'"
                            :selected_users="rolePermissionsUsersUid">
                    </organizationUserSelection>
                  </div>
                  <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="addUserDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addUserSubmit">确 定</el-button>
                  </span>
                  </template>
                </el-dialog>

              </el-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
// import "../assets/css/contract.css"
import "../assets/css/concord.css"
import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
import {
  getAllPermissions,
  getRoleDetail,
  saveRole,
  deleteRole,
} from "../api/api";
import {ElLoading, ElMessage} from "element-plus";
import UserIcon from "../components/UserIcon";
import organizationUserSelection from "../components/organizationUserSelection";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import {globalTagClick} from "../assets/js/tag/tag";
export default {
  name: "DataDictionary",
  components: {MetaRightSideHeader, ContractSettingLeftMenu,UserIcon,organizationUserSelection},
  data() {

    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      //拥有该权限的用户
      rolePermissionsUsers: [],
      rolePermissionsUsersUid: [],
      //用来显示perimission配置表的临时数组
      permissionsList:[],
      roleInfoPermissions:'',
      roleId: '',
      permissionsTempList:[],
      //该role拥有的permission的ids
      rRPermissions:[],
      addUserDialogVisible:false,
      warnMessageShow:false,
    }
  },
  watch: {
    'roleInfoPermissions.name': function () {
      if (this.roleInfoPermissions.name.length >= 3) {
        this.warnMessageShow = false;
      }
    }
  },
  mounted() {
    if(this.$route.params.id){
        this.roleId = this.$route.params.id
    }
    this.loadData()
    window.onresize=()=>{
      this.tableHeight=document.body.clientHeight - 150
    }
  },
  methods: {
    matchPath(path){
      return this.$route.path.indexOf(path)>-1
    },
    loadData(){
      getAllPermissions().then(res =>{
        if (res.status == 200) {
          this.permissionsList = res.data.data;
          if (this.roleId){
            getRoleDetail(this.roleId).then(re =>{
              this.roleInfoPermissions = re.data.data;
              if (this.roleInfoPermissions.id == 1){
                for (const i in this.permissionsList) {
                  this.permissionsTempList.push({id:this.permissionsList[i].id,name : this.permissionsList[i].name ,description: this.permissionsList[i].description ,isPermission: true});
                }
              }else{
                for (const i in this.permissionsList) {
                  if(this.roleInfoPermissions.permissionEntityIds.findIndex(item => {return item == this.permissionsList[i].id}) > -1) {
                    this.permissionsTempList.push({id:this.permissionsList[i].id,name : this.permissionsList[i].name ,description: this.permissionsList[i].description ,isPermission: true});
                    this.rRPermissions.push(this.permissionsList[i].id);
                  }else{
                    this.permissionsTempList.push({id:this.permissionsList[i].id,name : this.permissionsList[i].name ,description: this.permissionsList[i].description ,isPermission: false});
                  }
                }
              }
              this.rolePermissionsUsers = this.roleInfoPermissions.roleUserDTOS
            }).catch(err => {
              console.log(err)
              this.metaMessage['error']("网络链接错误");
            })
          }else{
            for (const i in this.permissionsList) {
              this.permissionsTempList.push({id:this.permissionsList[i].id,name : this.permissionsList[i].name ,description: this.permissionsList[i].description ,isPermission: false});
            }
            this.roleInfoPermissions = {name:'',description:'',permissionEntityIds:[],roleUserDTOS:[],roleType : 1}
          }

        }else{
          this.metaMessage['error']('网络链接错误');
        }
      }).catch(err => {
        console.log(err)
        this.metaMessage['error']("网络链接错误");
      })
    },
    refreshRRPermissions(row){
      if (row.isPermission){
        this.rRPermissions.push(row.id)
      }else{
        this.rRPermissions.splice(this.rRPermissions.findIndex(item => {return item == row.id}),1);
      }
    },
    addOrganizationUserClicked(){
      this.rolePermissionsUsersUid = this.rolePermissionsUsers.map((user) => {
        return user.uid;
      });
      this.addUserDialogVisible = true;
    },
    addUserDialogOpened(){
      this.$refs['organization-user-selection'].setSelectedObjects();
    },
    addUserSubmit(){
      const selectObjects = this.$refs['organization-user-selection'].getSelectedObjects();
      console.log("selectObjects =>", selectObjects)
      this.rolePermissionsUsers = [];
      for (const index in selectObjects) {
        this.rolePermissionsUsers.push({uid:selectObjects[index].objectId , userName:selectObjects[index].objectName ,
                                        headImgUrl:selectObjects[index].headimgurl, phone:selectObjects[index].phone,
                                        email:selectObjects[index].email ,roleId : null})
      }
      this.addUserDialogVisible = false;
    },
    saveRoleInfo(){
      globalTagClick('role-edit-save-role-info',{roleInfoPermissions:this.roleInfoPermissions});
      if (this.roleInfoPermissions.name.length < 3){

        this.warnMessageShow = true;
        return;
      }
      this.roleInfoPermissions.roleUserDTOS =  this.rolePermissionsUsers;
      this.roleInfoPermissions.permissionEntityIds =  this.rRPermissions;
      saveRole(this.roleInfoPermissions).then(res => {
        if(res.status==200){
          ElMessage.info('新建/修改角色成功');
        }else{
          ElMessage.error('新建/修改角色失败');
        }
      }).catch(e =>{
        console.log("ADD/UPDATE ROLE ERROR => ",e)
        ElMessage.error('网络链接错误');
      })
      this.$router.push({
        name: "role"
      })
    },
    deleteRoleInfo(){
      globalTagClick('role-edit-delete-role-info',{id:this.roleInfoPermissions.id});
      deleteRole(this.roleInfoPermissions.id).then(res => {
        if(res.status==200){
          ElMessage.info('删除角色成功');
        }else{
          ElMessage.error('系统角色无法删除');
        }
      }).catch(e =>{
        console.log("delete ROLE ERROR => ",e)
        ElMessage.error('网络链接错误');
      })
      this.$router.push({
        name: "role"
      })
    }
  }


}
</script>

<style scoped>

.data-container {
  text-align: left;

  background: white;
}


.data-dialog {
  text-align: left;

}

.el-dialog__header {
  text-align: left;
}

.addData-btn{
  height: 32px;
  box-sizing: border-box;
  min-height: unset;
  line-height: 6px;
  margin-left: 5px;
}

.el-table__header tr,
.el-table__header th {
  padding: 0;
  height: 20px;
  color: rgba(153,153,153,0.6);
  font-size: 6px;
}

.warningMessage {
  border-bottom: 2px solid red;
}
</style>
